import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Κλασικό Μπαλέτο";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>Στη σχολή μας διδάσκουμε το σύστημα VAGANOVA (Ρώσικο Σύστημα).</P>
        <P>
          Το μπαλέτο είναι η βάση για όλα τα είδη χορού με ένα παγκόσμιο
          κινητικό λεξιλόγιο που συμβάλλει στη σωστή στάση του σώματος, το
          συντονισμό των μελών του, την αίσθηση του ρυθμού, της μνήμης, της
          ενδυνάμωσης των μυών, χτίζοντας δυνατά και ευλύγιστα κορμιά.
        </P>
        <P>
          Ξεκινά από την ηλικία των 6 ετών και η ύλη εξελίσσεται σιγά σιγά,
          χρόνο με το χρόνο προχωρώντας σε ανώτερα επίπεδα.
        </P>
        <P>
          Η χάρη και η πλαστικότητα στην κίνηση είναι το κύριο χαρακτηριστικό
          της εκμάθησης του μπαλέτου.
        </P>
      </Section.Main>
    </Layout>
  );
}
